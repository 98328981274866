<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >

    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <b-col
          cols="6"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <b-form-group
            label="Registros por página"
          >
            <v-select
              v-model="perPage"
              :options="pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-form-group>
        </b-col>

        <!-- Search -->
        <b-col
          cols="6"
          md="6"
          class="topTable"
        >
          <div class="d-flex align-items-right justify-content-end">
            <b-button
              @click="$router.push({ name: 'add-provider', params: { }})"
            >
              Agregar proveedor
            </b-button>
          </div>
        </b-col>
      </b-row>

    </div>
      <!-- :items="fetchInvoices" -->
    <b-overlay
      :show="tableOverlay"
      rounded="sm"
    >
      <div id="main-table">
        <b-table
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="data"
          @row-clicked="$router.push({ name: 'edit-provider', params: { id: $event.idCompany }})"
          
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"

          ref="refInvoiceListTable"
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No hay registros"
          class="position-relative"
        >

          <!-- <template #head(invoiceStatus)>
            <feather-icon
              icon="TrendingUpIcon"
              class="mx-auto"
            />
          </template> -->

          <!-- Column: Id -->
          <template #cell(id)="data">
            <b-link
              class="font-weight-bold"
            >
              #{{ data.value }}
            </b-link>
          </template>


          <!-- Column: Status -->
          <template #cell(status)="data">
            <template v-if="data.value === 'ACTIVO'">
              <b-badge
                pill
                variant="light-success"
              >
                ACTIVO
              </b-badge>
            </template>
            <template v-if="data.value === 'INACTIVO'">
              <b-badge
                pill
                variant="light-warning"
              >
              INACTIVO
              </b-badge>
            </template>
          </template>

          <!-- Column: Invoice Status -->
          <!-- <template #cell(invoiceStatus)="data">
            <b-avatar
              :id="`invoice-row-${data.item.id}`"
              size="32"
              :variant="`light-${resolveInvoiceStatusVariantAndIcon(data.item.invoiceStatus).variant}`"
            >
              <feather-icon
                :icon="resolveInvoiceStatusVariantAndIcon(data.item.invoiceStatus).icon"
              />
            </b-avatar>
            <b-tooltip
              :target="`invoice-row-${data.item.id}`"
              placement="top"
            >
              <p class="mb-0">
                {{ data.item.invoiceStatus }}
              </p>
              <p class="mb-0">
                Balance: {{ data.item.balance }}
              </p>
              <p class="mb-0">
                Due Date: {{ data.item.dueDate }}
              </p>
            </b-tooltip>
          </template> -->

          <!-- Column: Client -->
          <!-- <template #cell(client)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.avatar"
                  :text="avatarText(data.item.client.name)"
                  :variant="`light-${resolveClientAvatarVariant(data.item.invoiceStatus)}`"
                />
              </template>
              <span class="font-weight-bold d-block text-nowrap">
                {{ data.item.client.name }}
              </span>
              <small class="text-muted">{{ data.item.client.companyEmail }}</small>
            </b-media>
          </template> -->

          <!-- Column: Issued Date -->
          <!-- <template #cell(issuedDate)="data">
            <span class="text-nowrap">
              {{ data.value }}
            </span>
          </template> -->

          <!-- Column: Balance -->
          <!-- <template #cell(balance)="data">
            <template v-if="data.value === 0">
              <b-badge
                pill
                variant="light-success"
              >
                Paid
              </b-badge>
            </template>
            <template v-else>
              {{ data.value }}
            </template>
          </template> -->

          <!-- Column: Actions -->
          <template #cell(actions)="data">

            <div class="text-nowrap">
              <!-- <feather-icon
                :id="`invoice-row-${data.item.id}-send-icon`"
                icon="SendIcon"
                class="cursor-pointer"
                size="16"
              />
              <b-tooltip
                title="Send Invoice"
                class="cursor-pointer"
                :target="`invoice-row-${data.item.id}-send-icon`"
              /> -->

              <feather-icon
                :id="`order-row-${data.item.id}-preview-icon`"
                icon="EyeIcon"
                size="16"
                class="mx-1"
                @click="$router.push({ name: 'edit-salesOrders', params: { id: data.item.id }})"
              />
                <!-- @click="$router.push({ name: 'order-preview', params: { id: data.item.id }})" -->
              <!-- <b-tooltip
                title="Preview Order"
                :target="`order-row-${data.item.id}-preview-icon`"
              /> -->

              <!-- <feather-icon
                :id="`order-row-${data.item.id}-edit-icon`"
                icon="EditIcon"
                size="16"
                class="mx-1"
                @click="$router.push({ name: 'order-edit', params: { id: 5036 }})"
              /> -->
                <!-- @click="$router.push({ name: 'order-edit', params: { id: data.item.id }})" -->

              <!-- <b-tooltip
                title="Edit Order"
                :target="`order-row-${data.item.id}-edit-icon`"
              /> -->

              <!-- <b-dropdown
                variant="link"
                toggle-class="p-0"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >

                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item>
                  <feather-icon icon="DownloadIcon" />
                  <span class="align-middle ml-50">Download</span>
                </b-dropdown-item>
                <b-dropdown-item :to="{ name: 'apps-invoice-edit', params: { id: data.item.id } }">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Edit</span>
                </b-dropdown-item>
                <b-dropdown-item>
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Delete</span>
                </b-dropdown-item>
                <b-dropdown-item>
                  <feather-icon icon="CopyIcon" />
                  <span class="align-middle ml-50">Duplicate</span>
                </b-dropdown-item>
              </b-dropdown> -->
            </div>
          </template>

        </b-table>
      </div>
    </b-overlay>
    <br>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, BOverlay, BFormGroup,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import useInvoicesList from './useInvoiceList'

import invoiceStoreModule from './invoiceStoreModule'



export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    ToastificationContent,
    BOverlay,
    BFormGroup,

    vSelect,
  },
  data() {
    return {
      tableColumns: [
        { key: 'companyName', label: 'Proveedor', sortable: true },
        { key: 'address', label: 'Dirección', sortable: true },
        { key: 'contact', label: 'Teléfono', sortable: true },
        { key: 'companyEmail', label: 'Correo', sortable: true },
      ],
      perPage: 5,
      pageOptions: [5, 10, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: 'id',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      onFiltered: [],

      data: [],
      tableOverlay: true,

    }
  },
  created() {
    this.getCompanies();
  },
  methods: {
    getCompanies() {
      this.$axios.get(this.$services + 'providers/get_providers').then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          this.data = res.data.data;
          this.totalRows = res.data.data.length;
        }
        this.tableOverlay = false;
      }).catch(error => this.$verifyToken(error.response.data.data));
    },
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-orders'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      fetchInvoices,
    } = useInvoicesList()

    return {
      fetchInvoices,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/customHelios.scss';
</style>
